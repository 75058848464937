import * as React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Card from '@mui/joy/Card';


const ContactForm = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [postRes, setPostRes] = React.useState({
    message:'',
    color:''
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setPostRes(
      {
        message:'',
         color:''
      }
       );
  };

  const handleSubmit = async(event) => {
    event.preventDefault(); 

axios.post('https://us-central1-learnwithapp-661e3.cloudfunctions.net/addEmail',formData, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
).then(function (response) {
  console.log('response ',response)
  setFormData({
    name: '',
    email: '',
    message: ''
  });

  setPostRes(
 {
   message:response.data,
    color:'success'
 }
  );
})
.catch(function (error) {
  
  setPostRes(
    {
      message:error.message,
       color:'error'
    }
  );
});

  };

  return (
    <Card sx={{  opacity:0.6 , backgroundColor : '#bde0fe', border : 0}}>
   
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              margin="normal"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label="Message"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
         <Typography color={postRes.color} variant="body1" gutterBottom>
         <div className="content" dangerouslySetInnerHTML={{__html:postRes.message}}></div>
        
          </Typography>
        <Button variant="contained" type="submit">
          Send Message
        </Button>
          </form>
       </Card>
  );
};

export default ContactForm;