import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const AboutPage = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'   
 }}>
        <Box sx={{ flex: 1, p: 2 }}>
          <Typography variant="h4">About Us</Typography>
          <Typography variant="body1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna   
 aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </Typography>
        </Box>
        <Box sx={{   
 flex: 1, p: 2, textAlign: 'right' }}>
          <img src="https://firebasestorage.googleapis.com/v0/b/classinapp.appspot.com/o/Digitload%2Fpexels-peaky-29445973.jpg?alt=media&token=c30c8e9a-9fa4-4d51-8156-44e4c070357b" width={300} height={200} />
        </Box>
      </Box>
      <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
        <Button variant="contained">Learn More</Button>
      </Box>
      <Box sx={{ width: '100%', mt: 4 }}>
        <img src="https://firebasestorage.googleapis.com/v0/b/classinapp.appspot.com/o/Digitload%2F33.JPG?alt=media&token=38ced304-c949-4f5b-a5a7-430e696c9386" alt="Footer image" width={'100%'} height={200} />
      </Box>
    </Box>
  );
};

export default AboutPage;