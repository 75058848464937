import * as React from 'react';
import { useNavigate} from "react-router";
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import { Box } from '@mui/joy';

export default function About() {
  let navigate = useNavigate();
  return (

    <Grid
  container
  spacing={{ xs: 2, md: 3 }}
  columns={{ xs: 4, sm: 8, md: 12 }}
  sx={{ flexGrow: 1 }}
>
 
    <Grid  size={{ xs: 2, sm: 4, md: 4 }}>
    <Card sx={{ maxWidth: 900, opacity:0.7 , backgroundColor : '#bde0fe', border : 0}}>
      <div>
        <Typography level="h1">Who we are</Typography>
        <Typography level="h3">Your Partner in Digital Transformation</Typography>
       
      </div>
     
      <CardContent orientation="horizontal">
      <Typography level="body-lg">DIGITLOAD is a leading technology solutions provider dedicated to empowering businesses through innovation and digital transformation. With a focus on delivering cutting-edge strategies and technological advancements, we help organizations achieve their full potential.</Typography>
      
      </CardContent>
      <div>
       
        <Typography level="h3">Our Mission</Typography>
       
      </div>
     
      <CardContent orientation="horizontal">
      <Typography level="body-lg">To drive digital innovation and empower businesses to thrive in the ever-evolving technological landscape.</Typography>
      
      </CardContent>
      <div>
       
       <Typography level="h3">Our Vision</Typography>
      
     </div>
    
     <CardContent orientation="horizontal">
     <Typography level="body-lg">To be the trusted partner for businesses seeking to harness the power of technology to achieve sustainable growth and success.</Typography>
     
     </CardContent>

     <div>
       
       <Typography level="h3">Our Services</Typography>
      
     </div>
    
     <CardContent orientation="horizontal">
        <ul>
           <li>
           <Typography level="body-lg"><strong>Strategic Consulting:</strong> Gain insights into industry trends and develop a roadmap for sustainable growth.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Digital Transformation:</strong> Modernize your operations and customer experiences with innovative digital solutions.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Technology Integration:</strong> Seamlessly integrate cutting-edge technologies to optimize your business processes.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Data-Driven Insights:</strong> Leverage data analytics to make informed decisions and drive business intelligence.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Cloud Solutions:</strong> Harness the power of the cloud to optimize your IT infrastructure and reduce costs.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>AI and Machine Learning:</strong> Automate tasks, improve decision-making, and enhance customer experiences.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Cybersecurity:</strong> Protect your business from cyber threats with robust security solutions.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>IoT Integration:</strong> Connect devices and systems to gain valuable insights and streamline operations.</Typography>
     
            </li> 
        </ul>
    
     </CardContent>

     <div>
       
       <Typography level="h3">Why Choose DIGITLOAD?</Typography>
      
     </div>
    
     <CardContent orientation="horizontal">
        <ul>
           <li>
           <Typography level="body-lg"><strong>Expertise:</strong> Our team of experienced professionals possesses deep industry knowledge and technical expertise.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Innovation:</strong> We embrace cutting-edge technologies to deliver innovative solutions.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Customer Focus:</strong> We prioritize our clients' needs and tailor our services to their specific requirements.</Typography>
     
            </li> 
            <li>
           <Typography level="body-lg"><strong>Results-Oriented:</strong> We are committed to delivering measurable results and maximizing ROI.</Typography>
     
            </li> 
           
        </ul>

    
     </CardContent>
    

    </Card>
    </Grid>
    <Grid  size={{ xs: 2, sm: 4, md: 4 }}>
    <Card sx={{  maxWidth: 450, border : 0 , backgroundColor : '#bde0fe' }}>
 
     
        <img
          src="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2F2.jpg?alt=media&token=1c253289-a534-486f-8d26-a4b02befe793"
          loading="lazy"
          alt="About Digitload"
             width={'100%'} 
        />
     
     
     
    </Card>
        
    </Grid>
    <Card sx={{  width: '100%', border : 0 , backgroundColor : '#bde0fe' }}>
   
    <Box sx={{ width: '100%',  mb:12 }}>
  
    <Box align={'center'} >
       
       
       <Button
      color='primary'
  onClick={()=>{navigate("/Contact")}}
  size="lg"
  variant="solid"

> 
<Typography level="h3" color='white' >Join us on the journey to digital excellence.</Typography>
</Button>
     </Box>

      </Box>
     
    
      </Card>
</Grid>

    
  );
}
