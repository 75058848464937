import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';   
import CardMedia from '@mui/material/CardMedia';
import './App.css';
import { NavLink } from "react-router";
import ContactForm from './ContactForm';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
   backgroundColor : '#bde0fe',
   opacity:0.8,
  alignItems: 'center',
  borderColor : '#437CB1ff',
  padding: theme.spacing(0.8),
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const Home = () => {
 
  return (
    <Box sx={{ width: '100%', padding: 0.5 }}>
      
      {/* Hero Section */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" className='noto-serif-ahom-regular' gutterBottom>
          Explore the Future of Computer Science
          </Typography>
          <Typography variant="h5" gutterBottom>
            Empowering businesses through innovative technology.
          </Typography>
         
        </Grid>
        <Grid  xs={12} md={6}>
       
        </Grid>
      </Grid>

      {/* Services Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Our Services
        </Typography>
        <Grid container spacing={2}>
          {/* Add more service cards as needed */}
          <Grid item xs={12} md={4}>
          <NavLink 
              
                 to={'/Services'} 
                 style={({ isActive }) => ({
                  
                  textDecoration :'none' 
               
                })}
                
                 end>
          <StyledCard>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2FFuture-Proof%20Your%20Business.jpg?alt=media&token=9eccee38-dca8-4a87-9d41-46acc4c73c5f"
            alt="Service Image"
            sx={{ width: '100%' }}
          />
            <CardContent>
              <Typography variant="h5">Future-Proof Your Business with DIGITLOAD
              </Typography>
              <Typography variant="body1">
              Empower your business for the future with DIGITLOAD's cutting-edge strategies and technological advancements. Our solutions are designed to drive efficiency and innovation, tailored to meet your specific needs.
              </Typography>
             
            </CardContent>
          </StyledCard>
          </NavLink>
        </Grid>
        <Grid item xs={12} md={4}>
        <NavLink 
              
              to={'/Services'} 
              style={({ isActive }) => ({
               
               textDecoration :'none' 
            
             })}
             
              end>
          <StyledCard>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2Fdata%20analysis.jpg?alt=media&token=4875d223-f41a-4ba9-b4d7-5ccfa95d932c"
            alt="Service Image"
            sx={{ width: '100%' }}
          />
            <CardContent>
              <Typography variant="h5">Unleash the Power of Your Data with DIGITLOAD</Typography>
              <Typography variant="body1">
              Transform raw data into actionable insights that drive business growth. Our expert data analysts employ advanced techniques to uncover hidden patterns, trends, and opportunities within your datasets.
              </Typography>
             </CardContent>
          </StyledCard>
          </NavLink>
        </Grid>
        <Grid item xs={12} md={4}>
        <NavLink 
              
              to={'/Services'} 
              style={({ isActive }) => ({
               
               textDecoration :'none' 
            
             })}
        
              end>
          <StyledCard>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2FSolutions.jpg?alt=media&token=1c1bf426-f04c-4deb-a57c-9026df2f5dbb"
            alt="Service Image"
            sx={{ width: '100%' }}
          />
            <CardContent>
              <Typography variant="h5">Revolutionize Your Business with DIGITLOAD</Typography>
              <Typography variant="body1">
              Revolutionize your business operations with cutting-edge technology designed to enhance efficiency and drive growth. Our expertise ensures seamless integration, enabling you to stay ahead in an ever-evolving digital landscape.
              </Typography>
             </CardContent>
          </StyledCard>
         </NavLink>
        </Grid>
          {/* ... more service cards */}
        </Grid>
      </Box>
 {/* Hero Section */}
 <Grid container spacing={2}>
 <Grid item ml={1} xs={12} md={4}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2F2.jpg?alt=media&token=1c253289-a534-486f-8d26-a4b02befe793"
            alt="About our company"
            width={'100%'}           
            layout="responsive"
          />
        </Grid>
        <Grid item xs={12} md={6}>
        <NavLink 
              
              to={'/About'} 
              style={({ isActive }) => ({
                color: isActive ? "black" : "black",
               textDecoration :'none' 
            
             })}
             
              end>
          <Typography variant="h2" gutterBottom>
          About 
          our company
          </Typography>
          <Typography variant="h5" gutterBottom>
          Our team of seasoned computer scientists is dedicated to advancing the field through rigorous research and innovative solutions. With a commitment to excellence, our mission is to empower businesses and individuals with cutting-edge technology and expertise ...
          </Typography>
         </NavLink>
        </Grid>
      
      </Grid>
      {/* Contact Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ready to collaborate? Share your details, and we'll be in touch. Let's connect and explore new opportunities together.
        </Typography>
        {/* ... (contact form as in the previous response) */}
        <ContactForm/>
      </Box>
    </Box>
  );
};

export default Home;