import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';   

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';   


const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
   backgroundColor : '#bde0fe',
  alignItems: 'center',
  padding: theme.spacing(0.8),
  marginBottom: theme.spacing(0.5),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const Services = () => {
 
  return (
    <Box sx={{ width: '100%', paddingTop: 4, paddingBottom: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" sx={{ marginBottom: 2 }}>
            Our Services
          </Typography>
          <Typography variant="body1" mb={12}>
          Book your appointment today to explore advanced computer science solutions tailored to your organization's needs. Our experts are ready to assist you in harnessing the power of technology for your business growth.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2Fservices_digitload.png?alt=media&token=afc7197c-13f4-4be3-9aec-ac618101e94e"
            alt="Service DIGITLOAD"
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} mt={4}>
        {/* Add additional service cards here */}
        <Grid item xs={12} md={4}>
          <StyledCard>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2FFuture-Proof%20Your%20Business.jpg?alt=media&token=9eccee38-dca8-4a87-9d41-46acc4c73c5f"
            alt="Service Image"
            sx={{ width: '100%' }}
          />
            <CardContent>
              <Typography variant="h5">Future-Proof Your Business with DIGITLOAD
              </Typography>
              <Typography variant="body1">
              Empower your business for the future with DIGITLOAD's cutting-edge strategies and technological advancements. Our solutions are designed to drive efficiency and innovation, tailored to meet your specific needs.
              </Typography>
              <Typography variant="h6">Experience the DIGITLOAD difference:</Typography>
              <ul>
                <li>
                <Typography variant="body2"><strong>Strategic Consulting:</strong> Gain insights into industry trends and develop a roadmap for sustainable growth.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Digital Transformation:</strong> Modernize your operations and customer experiences with innovative digital solutions.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Technology Integration:</strong> Seamlessly integrate cutting-edge technologies to optimize your business processes.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Data-Driven Insights:</strong> Leverage data analytics to make informed decisions and drive business intelligence.</Typography>
                </li>
              </ul>
              <Typography variant="h6">Let's build a brighter future together.</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <div id="test1"></div>
        <Grid item   xs={12} md={4}>
          <StyledCard>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2Fdata%20analysis.jpg?alt=media&token=4875d223-f41a-4ba9-b4d7-5ccfa95d932c"
            alt="Service Image"
            sx={{ width: '100%' }}
          />
            <CardContent>
              <Typography variant="h5">Unleash the Power of Your Data with DIGITLOAD </Typography>
              <Typography variant="body1">
              Transform raw data into actionable insights that drive business growth. Our expert data analysts employ advanced techniques to uncover hidden patterns, trends, and opportunities within your datasets.
              </Typography>
              <Typography variant="h6">Our data analysis services include:</Typography>
              <ul>
                <li>
                <Typography variant="body2"><strong>Data Cleaning and Preparation:</strong> Ensure data accuracy and consistency for reliable analysis.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Exploratory Data Analysis (EDA):</strong> Gain a comprehensive understanding of your data through visualization and statistical techniques.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Predictive Analytics:</strong> Forecast future trends and make informed decisions.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Prescriptive Analytics: </strong> Identify optimal solutions and recommendations.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Custom Data Solutions:</strong> Tailor our services to your specific business needs.</Typography>
                </li>
              </ul>
              <Typography variant="h6">Let DIGITLOAD help you harness the full potential of your data.</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledCard>
          <CardMedia
            component="img"
            image="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2FSolutions.jpg?alt=media&token=1c1bf426-f04c-4deb-a57c-9026df2f5dbb"
            alt="Service Image"
            sx={{ width: '100%' }}
          />
            <CardContent>
              <Typography variant="h5">Revolutionize Your Business with DIGITLOAD</Typography>
              <Typography variant="body1">
              Revolutionize your business operations with cutting-edge technology designed to enhance efficiency and drive growth. Our expertise ensures seamless integration, enabling you to stay ahead in an ever-evolving digital landscape.
              </Typography>
              <Typography variant="h6">Our services include:</Typography>
              <ul>
                <li>
                <Typography variant="body2"><strong>Cloud Solutions:</strong> Leverage the power of the cloud to optimize your IT infrastructure and reduce costs.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>AI and Machine Learning:</strong> Harness the potential of AI to automate tasks, improve decision-making, and enhance customer experiences.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>Cybersecurity:</strong>  Protect your business from cyber threats with robust security solutions.</Typography>
                </li>
                <li>
                <Typography variant="body2"><strong>IoT Integration:</strong> Connect devices and systems to gain valuable insights and streamline operations.</Typography>
                </li>
              </ul>
              <Typography variant="h6">Partner with DIGITLOAD to unlock the full potential of technology.</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;