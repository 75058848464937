import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from "react-router";
import Grid from '@mui/joy/Grid';
import { Box } from '@mui/joy';
import Card from '@mui/joy/Card';
import { Routes, Route, useNavigate} from "react-router";
import Home from './home';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import './App.css';
import AboutPage from './AboutPage';
import Logo from './logo512.png'

const drawerWidth = 240;
const navItems = ['Home','Services', 'About', 'Contact'];


function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  let navigate = useNavigate();
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography  onClick={()=>{navigate("/")}}
         variant="h6" sx={{ my: 2, fontFamily :'noto-serif-ahom-regular' }}>
        DIGITLOAD
      </Typography>

      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
           
              <NavLink 
               key={item}
               
                 to={item} 
                 style={({ isActive }) => ({
                  color: isActive ? "#fca311" : "#1b263b",
                  textDecoration :'none' 
               
                })}
                
                 end>
               <ListItemText primary={item} />
            </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex',  backgroundColor : '#a2d2ff' }}>
      <CssBaseline />
      <AppBar component="nav" sx = {{backgroundColor : '#023e8a'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
         

          <Box  m={0.5}
          >
          <img 
          src={Logo}
          height='50'
          onClick={()=>{navigate("/")}}
          />
         </Box>
         
          <Typography
            variant="h6"
            component="div"
            onClick={()=>{navigate("/")}}
            sx={{ flexGrow: 1 , fontFamily : 'Noto Serif Ahom', display: { xs: 'block', sm: 'block' } }}
          >
            DIGITLOAD
          </Typography>
        
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item}  >
               <NavLink 
               key={item}
               
                 to={item} 
                 style={({ isActive }) => ({
                  color: isActive ? "#fca311" : "#fff",
                  textDecoration :'none' 
               
                })}
                
                 end>
              {item}
            </NavLink>
              </Button>
              
              
              
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        sx={{
          position: 'fixed',
         
          top:0,
          opacity:0.25
       
                 
        }}
        >
      <img
            
            src="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2Fcoin.png?alt=media&token=3db73fbf-0c3c-4ab2-aab8-53031a4dc7df"
             alt=""
             width={'100%'}
          />
          </Box>
       
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Grid
  container
  direction="column"
  sx={{
    justifyContent: "space-between",
    alignItems: "center",
  }}
>
          <Grid >
     
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="Home" element={<Home />} />
  <Route path="About" element={<About />} />
  <Route path="Services" element={<Services />} />
  <Route path="Contact" element={<Contact/>} />
  <Route path="AboutPage" element={<AboutPage />} />
 
    </Routes>
 
          </Grid>
        
          <Grid >
          
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          mt: 0,
          width: '100%',
          minHeight : 100,
          backgroundColor : '#003049',
          color: 'white',
          textAlign: 'center',
                
        }}
        >
        <Grid 
         container
         direction="row"
         sx={{
           justifyContent: "space-around",
           alignItems: "flex-start",
         }}
         >
              <Grid >
              <NavLink 
              
              to={'/Home'} 
              style={({ isActive }) => ({
               textDecoration :'none' 
            
             })}
             
              end>
                    <Card variant="outlined " sx={{backgroundColor : '#003049', maxWidth: 400 }}>
                      <Typography level="h1"  color='white' sx={{ fontSize: '30px', fontFamily : 'Noto Serif Ahom', mt: 3 }}>DIGITLOAD</Typography>
                      
                    </Card>
                    </NavLink>
              </Grid>
              <Grid >
                    <Card variant="outlined " sx={{backgroundColor : '#003049', maxWidth: 400 }}>
                      <Typography level="h1"  color='white' sx={{ fontSize: '25px',fontFamily : 'Noto Serif Ahom', mt: 3 }}>Location</Typography>
                      <Typography level="h1" color='white'sx={{ fontSize: '18px',fontFamily : 'Noto Serif Ahom' }}>330 Bay Street</Typography>
                      <Typography level="h1" color='white' sx={{ fontSize: '18px',fontFamily : 'Noto Serif Ahom' }}>Toronto, ON M5H 2S8, Canada</Typography>
                    </Card>
              </Grid>
              <Grid >
              <NavLink 
              
              to={'/Contact'} 
              style={({ isActive }) => ({
               textDecoration :'none' 
            
             })}
             
              end>
                    <Card variant="outlined " sx={{backgroundColor : '#003049',  maxWidth: 400 }}>
                      <Typography level="h1" color='white' sx={{ fontSize: '25px',fontFamily : 'Noto Serif Ahom', mt: 3 }}>Contact us</Typography>
                     
                    </Card>
                    </NavLink>
              </Grid>
            
        </Grid>
  </Box>
  
          </Grid>
        
        </Grid>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
