import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ContactForm from './ContactForm';

const Contact = () => {
  

  return (
    <Box sx={{ width: '100%', padding: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
          Interested in working together? Please provide your contact information, and we'll reach out to discuss potential projects. We're excited to hear from you.
          </Typography>
         <ContactForm/>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/learnwithapp-661e3.firebasestorage.app/o/Digitload%2Ftoronto.jpg?alt=media&token=1c8f57fa-7081-47b8-9a7a-78988950c0a6"
            alt="Contact Us"
            width={'100%'} // Adjust width as needed
            // Adjust height as needed
            layout="responsive"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;